import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.stg.calfrenzy.bitstudios.dev/',
  appUrl: 'https://stg.calfrenzy.bitstudios.dev',
  publicUrl: 'https://stg.calfrenzy.bitstudios.dev/',
  domain: '',
  envName: 'stg',
  googleClientId:
    '369573383535-phvu70n2klaevh7vh1s0u3pjrkgl5goc.apps.googleusercontent.com',
  googleMapId: 'AIzaSyCMQC8InSaMfLqxtPGumLRWGEQTD8gHuhs',
  zoomClientId: 'BvATuJS_RJGIzYSNBxQKA',
  buildVersion: '3c5104a78600aad27154dc2a9a9dbdd504306cbf',
};
